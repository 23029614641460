import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Topicfutbolmx from "../components/topicfutbolmx"
import Notesfutbolmx from "../components/notesfutbolmx"
import Mainfutbolmx from "../components/mainfutbolmx"

const FutbolMXPage = () => (
  <>
    <SEO title="Futbol Mexicano" />
    <Helmet>
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <div className="grid-container-templatenota">
      <div className="templatenota-banner-section"></div>
      <div>
        <Mainfutbolmx />
        <Topicfutbolmx />
        <Notesfutbolmx />
      </div>
      <div className="templatenota-banner-section"></div>
    </div>
  </>
)

export default FutbolMXPage
